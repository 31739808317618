import tippy from 'tippy.js';
require('../bootstrap');

document.addEventListener('DOMContentLoaded', function () {
    setTimeout(() => {
        const elements = document.querySelectorAll('[data-tooltip]');

        if (!elements) {
            return;
        }

        for (const element of elements) {
            let options = {};

            if (element.dataset.tooltip) {
                const attrOptions = JSON.parse(element.dataset.tooltip);
                options = {
                    ...options,
                    ...attrOptions
                };
            }

            const title = element.getAttribute('title');

            if (!title) {
                return;
            }

            element.removeAttribute('title');

            tippy(element, {
                content: title,
                ...options
            });
        }
    });

});
